<template>
    <marketing>
        <marketing-hero>
            <p class="section-header text-center text-muted mb-3">
                {{ $t('pages.marketing.importantDisclosures.header') }}
            </p>
            <h3 class="fw-bold mb-3">
                {{ $t('pages.marketing.importantDisclosures.title') }}
            </h3>
            <h5 class="fw-light text-muted mb-0 px-lg-4">
                {{ $t('pages.marketing.importantDisclosures.subTitle') }}
            </h5>
        </marketing-hero>
        <section-block class="pt-0">
            <div class="container-lg">
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <list-button
                            :title="$t('pages.marketing.importantDisclosures.charmHandbook')"
                            image-src="global/download_document.svg"
                            href="/docs/CFPBCharmBooklet.pdf"
                        />
                    </div>
                    <div class="col-12 col-lg-6">
                        <list-button
                            :title="$t('pages.marketing.importantDisclosures.helocBooklet')"
                            image-src="global/download_document.svg"
                            href="/docs/CFPBHELOCBooklet.pdf"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <list-button
                            :title="$t('pages.marketing.importantDisclosures.eSignConsent')"
                            image-src="global/download_document.svg"
                            href="/docs/ESIGNConsent.pdf"
                        />
                    </div>
                    <div class="col-12 col-lg-6">
                        <list-button
                            :title="$t('pages.marketing.importantDisclosures.earlyHelocDisclosure')"
                            image-src="global/download_document.svg"
                            href="/docs/EarlyHELOCDisclosure.pdf"
                        />
                    </div>
                </div>
            </div>
        </section-block>
    </marketing>
</template>

<script>
    import Marketing from '../../layouts/Marketing'
    import SectionBlock from '@/components/SectionBlock'
    import ListButton from '@/components/ListButton'
    import MarketingHero from '@/components/MarketingHero'

    export default {
        metaInfo: {
            title: 'Aven Disclosures',
            meta: [{ name: 'description', vmid: 'description', content: 'Get insights about our products and have easy access to our disclosures.' }],
        },
        mounted: async function () {
            this.$logEvent('view_important_disclosures')
        },
        components: {
            SectionBlock,
            Marketing,
            ListButton,
            MarketingHero,
        },
    }
</script>
